import React from 'react';

// Components
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';

const ErrorPage = () => {
  return (
    <Layout>
      <Seo title="404 not found" />
      <Hero
        title="Whoops"
        description="Sorry, it looks like the page you’re looking for has broken down."
        ctas={[
          {
            title: 'Take me home',
            label: 'Take me home',
            ariaLabel: 'Navigate to home page',
            variant: 'Secondary',
            internalUrl: [
              {
                __typename: 'ContentfulHomePage',
              },
            ],
          },
        ]}
      />
    </Layout>
  );
};

export default ErrorPage;
